import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql, StaticQuery } from 'gatsby'
import Post from '../components/Post'
import PaginationLinks from '../components/PaginationLinks'
import { Container , Row ,Col  } from 'reactstrap'

const BlogPage = () => {
  const postsPerPage = 6
  let numberOfPages
  return (
    <Layout pageTitle="CodeBlog">
    <SEO title="Bilgi Merkezi - Blog" keywords={[`istanbul Web Tasarım`, `Web Tasarım Bilgisi`, `Web Tasarım Blog`]} description="Web Tasarım bloğumuz ile sizler de web sitesi kodlamaya başlayın !" />

      <section className="section pagebanner inpage">
        <Container>
          <Row>
            <Col md={{ size: 8, offset: 2 }} className="text-center">
              <h1>Bilgi Merkezi</h1>
              <p>Bloğumuz sürekli güncel ! Teknolojik yazılımlar , kodlama dilleri , web tasarım tüyoları ve çok daha fazlası...</p>
            </Col>
          </Row>
        </Container>
      </section>
      <StaticQuery
        query={BlogQuery}
        render={data => {
          numberOfPages = Math.ceil(
            data.allMarkdownRemark.totalCount / postsPerPage
          )
          return (
            <Container>
            <div>
            <Row>
              {data.allMarkdownRemark.edges.map(({ node }) => (
                <Post
                  key={node.id}
                  title={node.frontmatter.title}
                  slug={node.fields.slug}
                  author={node.frontmatter.author}
                  body={node.excerpt}
                  date={node.frontmatter.date}
                  fluid={node.frontmatter.image.childImageSharp.fluid}
                  tags={node.frontmatter.tags}
                />
              ))}
              <PaginationLinks currentPage={1} numberOfPages={numberOfPages} />
              </Row>
            </div>

            </Container>
          )
        }}
      />
    </Layout>
  )
}

const BlogQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 6
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMM Do YYYY")
            author
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default BlogPage
